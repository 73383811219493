(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name entitat.informacio.controller:InformacioEntitatCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.entitats.entitat.informacio')
    .controller('InformacioEntitatCtrl', InformacioEntitatCtrl);

  function InformacioEntitatCtrl($state, $stateParams, $filter, $mdDialog, entitat, towns, Entitats, $mdToast, entityTypes, AclService) {
    var vm = this;

    vm.can = AclService.can;
    vm.entityTypes = entityTypes;

    vm.ctrlName = 'InformacioEntitatCtrl';
    vm.entitat = entitat;
    vm.towns = towns;
    vm.edit = false;
    vm.isOpen = false;

    //Buttons
    vm.buttons = {
      dial: true,
      icon: 'more_vert',
      tooltip: 'Accions',
      actions: []
    };

    if (vm.can('ENTITY', 'U')) {
      vm.buttons.actions.push(
        {
          changeState: false,
          execute: enableEdit,
          icon: 'edit',
          tooltip: $filter('translate')('EDIT')
        }
      )
    }
    if (vm.can('ENTITY', 'R')) {
      vm.buttons.actions.push(
        {
          changeState: false,
          execute: printCard,
          icon: 'print',
          tooltip: $filter('translate')('PRINT_CARDS.PRINT_CARD')
        }
      )
    }
    if (vm.can('ENTITY', 'D')) {
      vm.buttons.actions.push(
        {
          changeState: false,
          execute: deleteEntity,
          icon: 'delete',
          attrClass: 'md-warn',
          tooltip: $filter('translate')('DELETE')
        }
      )
    }

    vm.updateEntitat = updateEntitat;

    vm.disableEdit = function () {
      vm.edit = false;
      vm.buttons.dial = true;
    };

    function enableEdit() {
      vm.edit = true;
      vm.buttons.dial = false;
    }

    function printCard() {
      $state.go('home.entitats.entitat.printCards', $stateParams)
    }

    function updateEntitat() {
      Entitats.update({id: $stateParams.entitatId}, vm.entitat, function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Entitat modificada correctament')
              .position('bottom left')
              .hideDelay(3000)
          );
          $state.reload();
        },
        function () {
          $mdToast.show(
            $mdToast.simple()
              .textContent('Hi ha hagut algun error')
              .position('bottom left')
              .hideDelay(3000)
          );
        });
    }

    function deleteEntity() {
      $mdDialog.show({
        controller: function DialogController($scope, $mdDialog, $filter) {
          $scope.alertText = $filter('translate')('ALERT');
          $scope.textContent = $filter('translate')('ENTITIES.CONFIRM_DELETE');
          $scope.cancelText = $filter('translate')('CANCEL');
          $scope.deleteText = $filter('translate')('DELETE');

          $scope.hide = function () {
            $mdDialog.hide();
          };

          $scope.cancel = function () {
            $mdDialog.cancel();
          };

          $scope.delete = function () {
            //then
            $mdDialog.hide();
          };
        },
        templateUrl: 'home/entitats/entitat/delete-confirmation.tmpl.html',
        parent: angular.element(document.body),
        clickOutsideToClose: true
      })
        .then(function () {
          Entitats.remove({id: $stateParams.entitatId},
            function () {
              $mdToast.show(
                $mdToast.simple()
                  .textContent($filter('translate')('ENTITIES.SUCCESSFUL_DELETE'))
                  .position('bottom left')
                  .hideDelay(3000));
              $state.go('home.entitats', {}, {reload: true});
            },
            function (error) {
              var message = 'TOAST_ERROR';
              if (error.status > 0) {
                message = angular.isDefined(error.data.message) ? error.data.message : message;
              }
              $mdToast.show(
                $mdToast.simple()
                  .textContent($filter('translate')(message))
                  .position('bottom left')
                  .hideDelay(3000)
              );
            });
        });
    }
  }

}());
